.widget{
    padding: 20px 0 30px;
    li{
        a{
            color: #A7A7A7;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 18px;
        }
    }
}

.social{
    a{
        color: rgba(19, 19, 19, 0.8);
        font-size: 40px;
    }
}

@media (max-width: 575.98px){
    .widget{
        li{
            margin: 10px 0;
        }
    }
}