
.erlebe-das-area{
    background: url('../../assets/img/erlebe-bg.png') no-repeat center top;
    background-size: cover;
    padding: 90px 0 60px;
    position: relative;
    .btn-lg{
        background: #F24711;
        border: 6px solid #A22D09;
        padding: 10px 20px; 
        max-width: 441px;
        width: 100%;
        margin-top: 20px;
        &:after { 
            background-color: #303030; 
        }
    }
}
.erlebe-text{
    // .section-title{
    //     .h2{
    //         font-size: 30px;
    //     }
    // }
    p{
        font-weight: 600;
        font-size: 18px;
    }
}
.erlebe-img{
    img{
        width: 100%;
    }
}

@media (max-width: 1199.98px){
    .erlebe-img img {
        width: 150%;
    }
}