.autor-area {
    padding-top: 70px;
    margin-bottom: -30px;
    .section-title{
        margin-bottom: 50px;
    }
}

.autor-text {
    p {
        max-width: 635px;
        font-weight: 400;
        font-size: 20px;
        color: #A7A7A7;
        line-height: 30px;
    }
}

.autor-img {
    img {
        width: 100%;
    }
}

@media (max-width: 1199.98px){
    .autor-text {
        padding-bottom: 40px;
    }
}