.banner-area {
    position: relative;
    z-index: 1;
    padding-top: 220px;
    background: rgba(217, 217, 217, 0.4);

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: url('../../assets/img/banner-bg.png') no-repeat center;
        background-size: cover;
        clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    }

    .banner-text {
        padding-top: 50px;

        h1 {

            color: white;
            line-height: 42px;
            font-weight: 300;
            font-size: 40px;
            border-bottom: 3px solid #FFFFFF;
            display: inline-block;
            text-align: center;
            padding-bottom: 11px;

            span {
                font-family: 'Oxanium', cursive;
                font-weight: 700;
                font-size: 128px;
            }
        }

        p {
            font-weight: 500;
            font-size: 18px;
            color: white;
            max-width: 415px;
            padding: 30px 0 50px;
        }

    }

    .banner-img {
        position: relative;
        margin-bottom: -40px;
    }

    .drone-img {
        position: absolute;
        top: 60px;
        right: -200px;

    }
}

.banner-Ebook {
    background: rgba(217, 217, 217, 0.4);
    padding: 60px 0 50px;
    text-align: center;

    p {
        font-weight: 400;
        font-size: 18px;
        color: #A7A7A7;
        line-height: 30px;
    }

    h6 {
        font-weight: 700;
        font-size: 18px;
        color: #615757;
        margin-top: 30px;
    }
}

@media (max-width: 1199.98px){
    .book-img{
        img{
            max-width: 189%;
        }
    }
    .banner-area::before { 
        clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);
    }
}

@media (max-width: 991.98px){
    .banner-area::before{
        background-position: center left;
    }
    .book-img img {
        max-width: 145%;
    }
    .banner-area .drone-img { 
        right: -50px;
    }
    .banner-img{
        margin-top: 50px;
    }
    .banner-area { 
        padding-top: 170px; 
    }
    
    
}

@media (max-width: 575.98px){
    .drone-img img {
        width: 50%;
    }
    .banner-area .drone-img {
        right: -55%;
    }
    .banner-area .banner-text h1 span { 
        font-size: 95px;
    }
    .banner-area .banner-text h1 { 
        font-size: 29px; 
    }
}