.uber-das-area {
    background: url('../../assets//img/buch-bg.png') no-repeat center;
    background-size: cover;
    padding: 80px 0;


    .btn-lg {
        background: #F24711;
        border: 6px solid #A22D09;
        padding: 10px 20px;
        min-width: 361px;
        margin-top: 20px;
        &:after { 
            background-color: #303030; 
        }
         
    }
}

.uber-das-text {
    .h1 {
        line-height: 42px;
        font-weight: 300;
        font-size: 40px;
        border-bottom: 3px solid #FFFFFF;
        display: inline-block;
        text-align: center;
        padding-bottom: 11px;
        padding-top: 50px;
        margin-bottom: 40px;

        span {
            font-family: "Oxanium";
            font-weight: 700;
            font-size: 128px;
            line-height: 103px;
        }
    }

    p {
        max-width: 433px;
        padding-bottom: 20px;
    }
}

.book-row {
    margin-top: 50px;
}

.book-slider {}

.flip-slider {
    position: relative;
    margin-top: 50px;
    .stf__parent {
        margin: 0 auto;
    }

    .demoPage {
        // background-color: white;
        box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.12);
        // padding: 30px;
        box-shadow: rgba(155, 155, 155, 0.418) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        img{
            width: 100%;
            height: 100%;
        }
    }

    .--left {
        // border-right: 2px solid #eee;
    }

    p {
        font-size: 17px;
        text-align: justify;
    }

    .flip-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: 0;
        color: white;
        font-size: 50px;
    }
    .next{
        right: 0;
    }
}

@media (max-width: 1199.98px){
    .uber-das-area .btn-lg { 
        min-width: 380px; 
    }
}

@media (max-width: 991.98px){
    .flip-slider .flip-button {
        position: unset; 
    }
    .flip-click {
        display: flex;
        gap: 10px;
        margin-top: 50px;
        justify-content: center;
    }
}

@media (max-width: 575.98px){
    // .stf__parent{
    //     min-width: 320px !important;
    //     max-width: 320px !important;
    //     min-height: 450px !important;
    // }
    // .demoPage{ 
    //     height: 460px !important; 
    //     text-align: center;
    //     top: 0 !important;
    // }
    // .flip-slider .demoPage img{
    //     width: 320px;
    // }

    .uber-das-area .btn-lg {
        max-width: 380px;
        min-width: 300px;
    }
    .uber-das-text .h1 span { 
        font-size: 80px;
        line-height: 60px;
    }
    .uber-das-text .h1 { 
        font-size: 26px; 
    }

    
} 

@media (max-width: 450px){
    .--simple{
        width: 350px !important;
        height: 480px !important;
        // margin-left: -13px;
    }
    
    .flip-slider .stf__parent { 
        max-height: 416px;
        min-height: 0 !important;
    }
    .flip-slider .demoPage{
        height: 480px !important;
    }
    .flip-slider{
        margin-top: 130px;
    }
    .flip-click { 
        margin-top: 24px; 
    }
    
}