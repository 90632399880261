.die-antarfari-area {
    padding: 95px 0;
    .section-title{
        margin-bottom: 60px;
    }
}

.die-antarfari-item {
    .die-img {
        width: 186px;
        height: 186px;
        border-radius: 100%;
        background: #FAFAFA;
        display: grid;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .h5 { 
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color: #A7A7A7;
        max-width: 230px;
        margin: 11px auto 0;

    }
}